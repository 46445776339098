import React from 'react'

const PostInfo = ({ createdAt, readingTime, hasIcon = true }) => (
  <div className='post-info'>
    <div>
      {hasIcon && (
        <span className='post-info__icon' role='img' aria-label='writing hand'>
          ✍️
        </span>
      )}
      {createdAt}
    </div>
    <span className='post-info__separator'>&bull;</span>
    <div>
      {hasIcon && (
        <span className='post-info__icon' role='img' aria-label='clock'>
          ⏱️
        </span>
      )}
      {readingTime}
    </div>
  </div>
)

export default PostInfo
