import React from 'react'

// Gatsby
import { Link } from 'gatsby'

// Components
import PostInfo from '@components/PostInfo'

const BorderedItem = ({ title, slug, tags, createdAt, readingTime }) => (
  <div className='bordered-item'>
    <div className='bordered-item__icon'>
      <i className={`i-${tags[0]}`} />
    </div>
    <div className='bordered-item__content'>
      <Link className='bordered-item__link' to={slug}>
        {title}
      </Link>
      <PostInfo createdAt={createdAt} readingTime={readingTime} hasIcon={false} />
    </div>
  </div>
)

export default BorderedItem
