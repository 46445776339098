import React, { Fragment } from 'react'

// Components
import BorderedItem from '@components/BorderedItem'

// Utils
import { getReadingTime } from '@utils/getReadingTime'

const PostList = ({ edges, title }) => {
  return (
    <Fragment>
      <h1 className='title'>{title}</h1>

      {edges.map((edge, index) => {
        const { fields, frontmatter } = edge.node

        const { title, tags, createdAt } = frontmatter
        const { slug, readingTime } = fields

        const formattedReadingTime = getReadingTime(readingTime.minutes)

        return (
          <BorderedItem
            key={index}
            title={title}
            tags={tags}
            slug={slug}
            createdAt={createdAt}
            readingTime={formattedReadingTime}
          />
        )
      })}
    </Fragment>
  )
}

export default PostList
